import { useState, useEffect, useRef } from 'react';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { delay } from 'utils/delay';

interface Props {
  readonly children: React.ReactNode;
  readonly isSiteLocked: boolean;
}

export function SiteLockedPopover({ isSiteLocked, children }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const isOpenDisabled = useRef(false);

  const { t } = useTranslation();

  const openPopover = async () => {
    await delay(500);
    if (!isOpenDisabled.current) {
      setIsOpen(true);
    }
  };

  const cancelOpeningPopover = async () => {
    isOpenDisabled.current = true;
    await delay(500);
    isOpenDisabled.current = false;
  };

  const closePopover = async () => {
    setIsOpen(false);
    isOpenDisabled.current = true;
    await delay(1000);
    isOpenDisabled.current = false;
  };

  useEffect(() => {
    setIsOpen(false);
  }, [isSiteLocked]);

  if (isSiteLocked) {
    return (
      <>
        <Popover
          id="siteLockedPopover"
          anchorEl={anchorEl.current}
          open={!!isOpen}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          color="contrast"
        >
          <Box
            sx={{
              maxWidth: '320px',
              padding: 3,
            }}
          >
            <Stack>
              <Typography variant="caption" fontWeight="800">
                {t('site_locked_popover_title')}
              </Typography>
              <Typography variant="caption">{t('site_locked_popover_description')}</Typography>
            </Stack>
          </Box>
        </Popover>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onMouseEnter={openPopover} onMouseLeave={cancelOpeningPopover} ref={anchorEl}>
          {children}
        </div>
      </>
    );
  } else {
    return children;
  }
}
